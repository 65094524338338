import PropTypes from 'prop-types';

// Components
import AgreementsList from 'layouts/candidates/components/Agreements/components/AgreementsList';

// Functions
import { setTitle } from 'Util';

function CandidateAgreements({ candidate, disableFunc }) {
  setTitle('Candidate Agreements Link');

  const disable = () => {
    disableFunc();
  };

  return (
    <AgreementsList candidate={candidate} disableFunc={disable} />
  );
}

CandidateAgreements.propTypes = {
  candidate: PropTypes.string.isRequired,
  disableFunc: PropTypes.func.isRequired,
};

export default CandidateAgreements;
