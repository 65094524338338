import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Reducer Functions
import { disableLink, fetchLink, validateLink } from 'reducers/tokenLinksSlice';
import { fetchCandidateAgreements, fetchCandidatePersonal } from 'reducers/candidatesSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// @mui material components
import Grid from '@mui/material/Grid';

// Images
import logoNAS from 'assets/images/nas-logo.png';

// Components
import PageLayout from 'layouts/components/LayoutContainers/PageLayout';
import Footer from 'layouts/components/Footer';
import CandidateAgreements from './components/CandidateAgreements';
import Error from './components/Error';
import Loading from './components/Loading';
import Close from './components/Close';
import CandidateExperience from './components/CandidateExperience';

const selector = (state) => ({
  failed: state.link.failed,
  user: state.link.user,
  tokenLinkInfo: state.link.tokenLinkInfo,
});

function Links() {
  const {
    failed,
    user,
    tokenLinkInfo,
  } = useSelector(selector, shallowEqual);

  const dispatch = useDispatch();
  const { token } = useParams();

  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState('');
  const [candidate, setCandidate] = useState(undefined);

  useEffect(() => {
    if (token !== undefined && token !== null) {
      dispatch(validateLink({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (user.token !== undefined) {
      dispatch(fetchLink({ token }));
    }
  }, [dispatch, token, user]);

  useEffect(() => {
    if (tokenLinkInfo !== undefined && tokenLinkInfo !== null) {
      if (tokenLinkInfo.metadata !== undefined && tokenLinkInfo.metadata !== null) {
        if (tokenLinkInfo.metadata.section === 'agreements') {
          setSection('agreements');
          dispatch(fetchCandidateAgreements({ uuid: tokenLinkInfo.metadata.candidate }));
          dispatch(fetchCandidatePersonal({ uuid: tokenLinkInfo.metadata.candidate }));
          setLoading(false);
        } else if (tokenLinkInfo.metadata.section === 'experience') {
          setSection('experience');
          setLoading(false);
        }

        if (tokenLinkInfo.metadata.candidate !== undefined
          && tokenLinkInfo.metadata.candidate !== null) {
          setCandidate(tokenLinkInfo.metadata.candidate);
        }
      }
    }
  }, [dispatch, tokenLinkInfo]);

  useEffect(() => {
    if (failed) {
      setLoading(false);
    }
  }, [failed]);

  const handleDisable = () => {
    dispatch(disableLink({ token }));
    setSection('close');
  };

  return (
    <PageLayout>
      <Grid
        container
        direction="row"
        padding={3}
        sx={{
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <Grid item md={12}>
          <SuiBox
            component="img"
            src={logoNAS}
            width="10%"
            p={1}
            mb={1}
          />
        </Grid>
        <Grid item md={12}>
          {failed && (
            <Error />
          )}
          {loading && (
            <Loading />
          )}
          {section === 'agreements' && (
            <CandidateAgreements
              candidate={candidate}
              disableFunc={handleDisable}
            />
          )}
          {section === 'experience' && (
            <CandidateExperience
              candidate={candidate}
              disableFunc={handleDisable}
            />
          )}
          {section === 'close' && (
            <Close />
          )}
        </Grid>
      </Grid>
      <Footer />
    </PageLayout>
  );
}

export default Links;
