import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Reducer functions
import {
  fetchClinicalExperiences,
  fetchCountries,
} from 'reducers/commonSlice';
import { fetchCandidateExperiences } from 'reducers/candidatesSlice';

// Components
import ExperienceInfo from 'layouts/candidates/components/Experience/components/ExperienceInfo';
import CandidateSave from '../CandidateSave';

function CandidateExperience({ candidate }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchClinicalExperiences());

    if (candidate !== undefined) {
      dispatch(fetchCandidateExperiences({ uuid: candidate }));
    }
  }, [dispatch, candidate]);

  return (
    <>
      <ExperienceInfo
        uuid={candidate}
      />
      <CandidateSave candidate={candidate} />
    </>
  );
}

CandidateExperience.propTypes = {
  candidate: PropTypes.string.isRequired,
};

export default CandidateExperience;
