import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import PropTypes from 'prop-types';

// Reducer functions
import { setFailed, setSaved } from 'reducers/candidatesSlice';

// @material-ui core components
import { Backdrop, CircularProgress } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Components
import { saveExperience } from 'layouts/candidates/components/SavePanel/components/SaveExperience';

// Functions
import { failedCandidateMessage, savedCandidateMessage } from 'Util';

const selector = (state) => ({
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
  experience: state.candidate.experience,
});
function CandidateSave({ candidate }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.save' });
  const {
    saved,
    failed,
    errors,
    experience,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleSave = () => {
    setOpenBackdrop(true);
    saveExperience(t, dispatch, experience, candidate);
  };

  if (saved) {
    setOpenBackdrop(false);
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    setOpenBackdrop(false);
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save')}
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </SuiBox>
  );
}

CandidateSave.propTypes = {
  candidate: PropTypes.string.isRequired,
};

export default CandidateSave;
