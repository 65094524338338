import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CandidateService from 'services/candidate.service';
import MarketingService from 'services/marketing.service';
// eslint-disable-next-line import/no-cycle
import {
  fulfilledCreatedReducer,
  fulfilledReducer, fulfilledSavedReducer, pendingReducer, rejectionReducer,
} from 'Util';
import ReportService from '../services/report.service';

export const fetchCandidatesInfo = createAsyncThunk(
  'candidate/fetchCandidatesInfo',
  async (payload, { rejectWithValue }) => {
    try {
      return await CandidateService.getCandidates();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesOutlook = createAsyncThunk(
  'candidate/fetchCandidatesOutlook',
  async (payload, { rejectWithValue }) => {
    const {
      statuses,
      enable,
    } = payload;
    try {
      return await CandidateService.getCandidatesOutlook(statuses, enable);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateProfile = createAsyncThunk(
  'candidate/fetchCandidateProfile',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateProfile(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateProfile = createAsyncThunk(
  'candidate/createCandidateProfile',
  async (payload, { rejectWithValue }) => {
    const {
      firstName,
      middleName,
      lastName,
      preferredName,
      dob,
      enable,
      languageId,
      countryId,
      residenceCountryId,
      citizenshipId,
      candidateStatusId,
      ssn,
      preferredPaymentPlan,
      paymentPlanBilled,
      deferredPayment,
      contactMethod,
      deferredAmount,
      recruiterId,
      bsrUserId,
      candidateEmails,
      candidatePhones,
      candidateAddresses,
      recruitmentList,
    } = payload;
    try {
      return await CandidateService.createCandidateProfile(
        firstName,
        middleName,
        lastName,
        preferredName,
        dob,
        enable,
        languageId,
        countryId,
        residenceCountryId,
        citizenshipId,
        candidateStatusId,
        ssn,
        preferredPaymentPlan,
        paymentPlanBilled,
        deferredPayment,
        contactMethod,
        deferredAmount,
        recruiterId,
        bsrUserId,
        candidateEmails,
        candidatePhones,
        candidateAddresses,
        recruitmentList,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateProfile = createAsyncThunk(
  'candidate/updateCandidateProfile',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      firstName,
      middleName,
      lastName,
      preferredName,
      dob,
      enable,
      languageId,
      countryId,
      residenceCountryId,
      citizenshipId,
      candidateStatusId,
      ssn,
      preferredPaymentPlan,
      paymentPlanBilled,
      deferredPayment,
      contactMethod,
      deferredAmount,
      recruiterId,
      bsrUserId,
      recruitmentList,
    } = payload;

    try {
      return await CandidateService.updateCandidateProfile(
        uuid,
        firstName,
        middleName,
        lastName,
        preferredName,
        dob,
        enable,
        languageId,
        countryId,
        residenceCountryId,
        citizenshipId,
        candidateStatusId,
        ssn,
        preferredPaymentPlan,
        paymentPlanBilled,
        deferredPayment,
        contactMethod,
        deferredAmount,
        recruiterId,
        bsrUserId,
        recruitmentList,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateProfile = createAsyncThunk(
  'candidate/deleteCandidateProfile',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
    } = payload;
    try {
      return await CandidateService.deleteCandidateProfile(
        uuid,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateStatusHistory = createAsyncThunk(
  'candidate/fetchCandidateStatusHistory',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateStatusHistory(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatePhones = createAsyncThunk(
  'candidate/fetchCandidatePhones',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidatePhonesInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidatePhone = createAsyncThunk(
  'candidate/createCandidatePhone',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      phone,
      isDefault,
    } = payload;
    try {
      return await CandidateService.createCandidatePhone(
        candidateId,
        phone,
        isDefault,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidatePhone = createAsyncThunk(
  'candidate/updateCandidatePhone',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      phone,
      isDefault,
    } = payload;
    try {
      return await CandidateService.updateCandidatePhone(
        id,
        candidateId,
        phone,
        isDefault,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidatePhone = createAsyncThunk(
  'candidate/deleteCandidatePhone',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidatePhone(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const checkSimilarCandidatePhone = createAsyncThunk(
  'candidate/checkSimilarCandidatePhone',
  async (payload, { rejectWithValue }) => {
    const {
      phone,
    } = payload;
    try {
      return await CandidateService.checkSimilarCandidatePhone(
        phone,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateEmails = createAsyncThunk(
  'candidate/fetchCandidateEmails',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateEmailsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateEmail = createAsyncThunk(
  'candidate/createCandidateEmail',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      email,
      isDefault,
    } = payload;
    try {
      return await CandidateService.createCandidateEmail(
        candidateId,
        email,
        isDefault,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateEmail = createAsyncThunk(
  'candidate/updateCandidateEmail',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      email,
      isDefault,
    } = payload;
    try {
      return await CandidateService.updateCandidateEmail(
        id,
        candidateId,
        email,
        isDefault,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateEmail = createAsyncThunk(
  'candidate/deleteCandidateEmail',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateEmail(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const checkSimilarCandidateEmail = createAsyncThunk(
  'candidate/checkSimilarCandidateEmail',
  async (payload, { rejectWithValue }) => {
    const {
      email,
    } = payload;
    try {
      return await CandidateService.checkSimilarCandidateEmail(
        email,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateAddresses = createAsyncThunk(
  'candidate/fetchCandidateAddresses',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateAddressesInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateAddress = createAsyncThunk(
  'candidate/createCandidateAddress',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      address,
      cityId,
      stateId,
      countryId,
      postalCode,
      addressTypeId,
      isDefault,
    } = payload;
    try {
      return await CandidateService.createCandidateAddress(
        candidateId,
        address,
        cityId,
        stateId,
        countryId,
        postalCode,
        addressTypeId,
        isDefault,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateAddress = createAsyncThunk(
  'candidate/updateCandidateAddress',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      address,
      cityId,
      stateId,
      countryId,
      postalCode,
      addressTypeId,
      isDefault,
    } = payload;
    try {
      return await CandidateService.updateCandidateAddress(
        id,
        candidateId,
        address,
        cityId,
        stateId,
        countryId,
        postalCode,
        addressTypeId,
        isDefault,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateAddress = createAsyncThunk(
  'candidate/deleteCandidateAddress',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateAddress(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateDegrees = createAsyncThunk(
  'candidate/fetchCandidateDegrees',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateDegreesInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateDegree = createAsyncThunk(
  'candidate/createCandidateDegree',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      degree,
      educationLevelId,
      graduation,
      nameUnder,
      educationInstitutions,
    } = payload;
    try {
      return await CandidateService.createCandidateDegree(
        candidateId,
        degree,
        educationLevelId,
        graduation,
        nameUnder,
        educationInstitutions,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateDegree = createAsyncThunk(
  'candidate/updateCandidateDegree',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      degree,
      educationLevelId,
      graduation,
      nameUnder,
      educationInstitutions,
    } = payload;
    try {
      return await CandidateService.updateCandidateDegree(
        id,
        candidateId,
        degree,
        educationLevelId,
        graduation,
        nameUnder,
        educationInstitutions,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateDegree = createAsyncThunk(
  'candidate/deleteCandidateDegree',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateDegree(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateExperiences = createAsyncThunk(
  'candidate/fetchCandidateExperiences',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateExperiencesInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateExperience = createAsyncThunk(
  'candidate/createCandidateExperience',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      company,
      jobTitle,
      from,
      to,
      current,
      address,
      postalCode,
      cityId,
      stateId,
      countryId,
      clinicalExperiences,
      workType,
      supervisorName,
      supervisorTitle,
      supervisorEmail,
      supervisorPhone,
    } = payload;
    try {
      return await CandidateService.createCandidateExperience(
        candidateId,
        company,
        jobTitle,
        from,
        to,
        current,
        address,
        postalCode,
        cityId,
        stateId,
        countryId,
        clinicalExperiences,
        workType,
        supervisorName,
        supervisorTitle,
        supervisorEmail,
        supervisorPhone,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateExperience = createAsyncThunk(
  'candidate/updateCandidateExperience',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      company,
      jobTitle,
      from,
      to,
      current,
      address,
      postalCode,
      cityId,
      stateId,
      countryId,
      clinicalExperiences,
      workType,
      supervisorName,
      supervisorTitle,
      supervisorEmail,
      supervisorPhone,
    } = payload;
    try {
      return await CandidateService.updateCandidateExperience(
        id,
        candidateId,
        company,
        jobTitle,
        from,
        to,
        current,
        address,
        postalCode,
        cityId,
        stateId,
        countryId,
        clinicalExperiences,
        workType,
        supervisorName,
        supervisorTitle,
        supervisorEmail,
        supervisorPhone,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateExperience = createAsyncThunk(
  'candidate/deleteCandidateExperience',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateExperience(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateResume = createAsyncThunk(
  'candidate/fetchCandidateResume',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
    } = payload;
    try {
      return await CandidateService.getCandidateResume(
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateExperienceDocuments = createAsyncThunk(
  'candidate/fetchCandidateExperienceDocuments',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateExperienceDocumentsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const assignCandidateExperienceDocument = createAsyncThunk(
  'candidate/assignCandidateExperienceDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      documentId,
    } = payload;
    try {
      return await CandidateService.createCandidateExperienceDocument(
        candidateId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateExperienceDocument = createAsyncThunk(
  'candidate/deleteCandidateExperienceDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      documentId,
    } = payload;

    try {
      return await CandidateService.deleteCandidateExperienceDocument(
        candidateId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const uploadCandidateExperienceDocument = createAsyncThunk(
  'candidate/uploadCandidateExperienceDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      documentId,
      file,
    } = payload;

    try {
      return await CandidateService.uploadCandidateExperienceDocument(
        candidateId,
        documentId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateDocuments = createAsyncThunk(
  'candidate/fetchCandidateDocuments',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateDocumentsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateDocument = createAsyncThunk(
  'candidate/createCandidateDocument',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.createCandidateDocument(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateDocument = createAsyncThunk(
  'candidate/updateCandidateDocument',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      file,
    } = payload;

    try {
      return await CandidateService.updateCandidateDocument(
        id,
        candidateId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateDocument = createAsyncThunk(
  'candidate/deleteCandidateDocument',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateDocument(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateExpenses = createAsyncThunk(
  'candidate/fetchCandidateExpenses',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateExpensesInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateExpensesStats = createAsyncThunk(
  'candidate/fetchCandidateExpensesStats',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await ReportService.getCandidateExpensesStats(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateNotes = createAsyncThunk(
  'candidate/fetchCandidateNotes',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateNotesInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateNote = createAsyncThunk(
  'candidate/fetchCandidateNote',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      id,
    } = payload;
    try {
      return await CandidateService.getCandidateNoteInfo(uuid, id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateNote = createAsyncThunk(
  'candidate/createCandidateNote',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      date,
      title,
      note,
      section,
    } = payload;
    try {
      return await CandidateService.createCandidateNote(
        candidateId,
        date,
        title,
        note,
        section,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateNote = createAsyncThunk(
  'candidate/updateCandidateNote',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      date,
      title,
      note,
      section,
    } = payload;
    try {
      return await CandidateService.updateCandidateNote(
        id,
        candidateId,
        date,
        title,
        note,
        section,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateNote = createAsyncThunk(
  'candidate/deleteCandidateNote',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateNote(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateContacts = createAsyncThunk(
  'candidate/fetchCandidateContacts',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateContactsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateContact = createAsyncThunk(
  'candidate/fetchCandidateContact',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      id,
    } = payload;
    try {
      return await CandidateService.getCandidateContactInfo(uuid, id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateContact = createAsyncThunk(
  'candidate/createCandidateContact',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      date,
      title,
      note,
      contactMethodId,
    } = payload;
    try {
      return await CandidateService.createCandidateContact(
        candidateId,
        date,
        title,
        note,
        contactMethodId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateContact = createAsyncThunk(
  'candidate/updateCandidateContact',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      date,
      title,
      note,
      contactMethodId,
    } = payload;
    try {
      return await CandidateService.updateCandidateContact(
        id,
        candidateId,
        date,
        title,
        note,
        contactMethodId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateContact = createAsyncThunk(
  'candidate/deleteCandidateContact',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateContact(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateReminders = createAsyncThunk(
  'candidate/fetchCandidateReminders',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateRemindersInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateReminder = createAsyncThunk(
  'candidate/fetchCandidateReminder',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      id,
    } = payload;
    try {
      return await CandidateService.getCandidateReminderInfo(uuid, id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateReminder = createAsyncThunk(
  'candidate/createCandidateReminder',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      date,
      title,
      note,
      remindOn,
      remindTo,
    } = payload;
    try {
      return await CandidateService.createCandidateReminder(
        candidateId,
        date,
        title,
        note,
        remindOn,
        remindTo,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateReminder = createAsyncThunk(
  'candidate/updateCandidateReminder',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      date,
      title,
      note,
      remindOn,
      remindTo,
    } = payload;
    try {
      return await CandidateService.updateCandidateReminder(
        id,
        candidateId,
        date,
        title,
        note,
        remindOn,
        remindTo,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateReminder = createAsyncThunk(
  'candidate/deleteCandidateReminder',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateReminder(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateNotesTimeline = createAsyncThunk(
  'candidate/fetchCandidateNotesTimeline',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateNotesTimeline(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateLanguageProficiency = createAsyncThunk(
  'candidate/fetchCandidateLanguageProficiency',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateLanguageProficiencyInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateLanguageProficiency = createAsyncThunk(
  'candidate/createCandidateLanguageProficiency',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      candidateLanguageCourses,
      candidateLanguageTests,
      assessmentResult,
      assessmentDate,
      schoolName,
      languageProficiencyId,
    } = payload;
    try {
      return await CandidateService.createCandidateLanguageProficiency(
        candidateId,
        candidateLanguageCourses,
        candidateLanguageTests,
        assessmentResult,
        assessmentDate,
        schoolName,
        languageProficiencyId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateLanguageProficiency = createAsyncThunk(
  'candidate/updateCandidateLanguageProficiency',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      candidateLanguageCourses,
      candidateLanguageTests,
      assessmentResult,
      assessmentDate,
      schoolName,
      languageProficiencyId,
    } = payload;
    try {
      return await CandidateService.updateCandidateLanguageProficiency(
        id,
        candidateId,
        candidateLanguageCourses,
        candidateLanguageTests,
        assessmentResult,
        assessmentDate,
        schoolName,
        languageProficiencyId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateLanguageProficiency = createAsyncThunk(
  'candidate/deleteCandidateLanguageProficiency',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateLanguageProficiency(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const assignCandidateLanguageTestDocument = createAsyncThunk(
  'candidate/assignCandidateLanguageTestDocument',
  async (payload, { rejectWithValue }) => {
    const {
      proficiencyId,
      candidateId,
      testId,
      documentId,
    } = payload;
    try {
      return await CandidateService.createCandidateLanguageTestDocument(
        proficiencyId,
        candidateId,
        testId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateLanguageTestDocument = createAsyncThunk(
  'candidate/deleteCandidateLanguageTestDocument',
  async (payload, { rejectWithValue }) => {
    const {
      proficiencyId,
      candidateId,
      testId,
      documentId,
    } = payload;

    try {
      return await CandidateService.deleteCandidateLanguageTestDocument(
        proficiencyId,
        candidateId,
        testId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const uploadCandidateLanguageTestDocument = createAsyncThunk(
  'candidate/uploadCandidateLanguageTestDocument',
  async (payload, { rejectWithValue }) => {
    const {
      proficiencyId,
      candidateId,
      testId,
      documentId,
      file,
    } = payload;

    try {
      return await CandidateService.uploadCandidateLanguageTestDocument(
        proficiencyId,
        candidateId,
        testId,
        documentId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateLanguageCourseProgress = createAsyncThunk(
  'candidate/createCandidateLanguageCourseProgress',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      languageProficiencyId,
      courseId,
      level,
      date,
      progress,
      enrollmentDate,
      lastAccess,
    } = payload;
    try {
      return await CandidateService.createCandidateLanguageCourseProgress(
        candidateId,
        languageProficiencyId,
        courseId,
        level,
        date,
        progress,
        enrollmentDate,
        lastAccess,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateLanguageCourseProgress = createAsyncThunk(
  'candidate/updateCandidateLanguageCourseProgress',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      languageProficiencyId,
      courseId,
      level,
      date,
      progress,
      enrollmentDate,
      lastAccess,
    } = payload;
    try {
      return await CandidateService.updateCandidateLanguageCourseProgress(
        id,
        candidateId,
        languageProficiencyId,
        courseId,
        level,
        date,
        progress,
        enrollmentDate,
        lastAccess,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateLanguageCourseProgress = createAsyncThunk(
  'candidate/deleteCandidateLanguageCourseProgress',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      languageProficiencyId,
      courseId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateLanguageCourseProgress(
        id,
        candidateId,
        languageProficiencyId,
        courseId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatePersonal = createAsyncThunk(
  'candidate/fetchCandidatePersonal',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidatePersonal(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidatePersonal = createAsyncThunk(
  'candidate/createCandidatePersonal',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      weight,
      height,
      hairColor,
      eyeColor,
      raceId,
      willingCovidVaccine,
      willingInfluenzaVaccine,
      immigrationIssues,
      immigrationIssuesDetail,
      countryId,
      passportDateIssue,
      passportDateExpiration,
      passportNumber,
      currentlyInUs,
      currentImmigrationStatus,
      visaCategory,
      asylumApproved,
      issuedEad,
      issuedSsn,
      legallyMarried,
    } = payload;
    try {
      return await CandidateService.createCandidatePersonal(
        candidateId,
        weight,
        height,
        hairColor,
        eyeColor,
        raceId,
        willingCovidVaccine,
        willingInfluenzaVaccine,
        immigrationIssues,
        immigrationIssuesDetail,
        countryId,
        passportDateIssue,
        passportDateExpiration,
        passportNumber,
        currentlyInUs,
        currentImmigrationStatus,
        visaCategory,
        asylumApproved,
        issuedEad,
        issuedSsn,
        legallyMarried,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidatePersonal = createAsyncThunk(
  'candidate/updateCandidatePersonal',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      weight,
      height,
      hairColor,
      eyeColor,
      raceId,
      willingCovidVaccine,
      willingInfluenzaVaccine,
      immigrationIssues,
      immigrationIssuesDetail,
      countryId,
      passportDateIssue,
      passportDateExpiration,
      passportNumber,
      currentlyInUs,
      currentImmigrationStatus,
      visaCategory,
      asylumApproved,
      issuedEad,
      issuedSsn,
      legallyMarried,
    } = payload;
    try {
      return await CandidateService.updateCandidatePersonal(
        id,
        candidateId,
        weight,
        height,
        hairColor,
        eyeColor,
        raceId,
        willingCovidVaccine,
        willingInfluenzaVaccine,
        immigrationIssues,
        immigrationIssuesDetail,
        countryId,
        passportDateIssue,
        passportDateExpiration,
        passportNumber,
        currentlyInUs,
        currentImmigrationStatus,
        visaCategory,
        asylumApproved,
        issuedEad,
        issuedSsn,
        legallyMarried,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidatePersonalSignature = createAsyncThunk(
  'candidate/updateCandidatePersonalSignature',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      file,
    } = payload;

    try {
      return await CandidateService.updateCandidatePersonalSignature(
        id,
        candidateId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidatePersonalPassport = createAsyncThunk(
  'candidate/updateCandidatePersonalPassport',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      file,
    } = payload;

    try {
      return await CandidateService.updateCandidatePersonalPassport(
        id,
        candidateId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateUpcomingEvents = createAsyncThunk(
  'candidate/fetchCandidateUpcomingEvents',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateUpcomingEvents(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateLicenses = createAsyncThunk(
  'candidate/fetchCandidateLicenses',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateLicensesInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateLicense = createAsyncThunk(
  'candidate/createCandidateLicense',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      countryId,
      stateId,
      expiration,
      noExpiration,
      licenseNumber,
      issue,
    } = payload;
    try {
      return await CandidateService.createCandidateLicense(
        candidateId,
        countryId,
        stateId,
        expiration,
        noExpiration,
        licenseNumber,
        issue,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateLicense = createAsyncThunk(
  'candidate/updateCandidateLicense',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      countryId,
      stateId,
      expiration,
      noExpiration,
      licenseNumber,
      issue,
    } = payload;
    try {
      return await CandidateService.updateCandidateLicense(
        id,
        candidateId,
        countryId,
        stateId,
        expiration,
        noExpiration,
        licenseNumber,
        issue,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateLicenseDocument = createAsyncThunk(
  'candidate/updateCandidateLicenseDocument',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      files,
    } = payload;

    try {
      return await CandidateService.updateCandidateLicenseDocument(
        id,
        candidateId,
        files,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateLicense = createAsyncThunk(
  'candidate/deleteCandidateLicense',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateLicense(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateEvaluations = createAsyncThunk(
  'candidate/fetchCandidateEvaluations',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateEvaluationsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateEvaluation = createAsyncThunk(
  'candidate/createCandidateEvaluation',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      credentialEvaluatorId,
      processTypeId,
      startedDate,
      expirationDate,
      universityDocs,
      licenseDocsSubmitted,
      licenseDocsSubmissionMethodId,
      licenseDocsSubmissionDate,
      licenseDocsSubmissionTracking,
      licenseDocsSubmissionCarrier,
      translationSubmitted,
      translationSubmissionDate,
      universityDocsVerified,
      universityDocsVerificationDate,
      licenseVerified,
      licenseVerificationDate,
      cesIssueDate,
    } = payload;
    try {
      return await CandidateService.createCandidateEvaluation(
        candidateId,
        credentialEvaluatorId,
        processTypeId,
        startedDate,
        expirationDate,
        universityDocs,
        licenseDocsSubmitted,
        licenseDocsSubmissionMethodId,
        licenseDocsSubmissionDate,
        licenseDocsSubmissionTracking,
        licenseDocsSubmissionCarrier,
        translationSubmitted,
        translationSubmissionDate,
        universityDocsVerified,
        universityDocsVerificationDate,
        licenseVerified,
        licenseVerificationDate,
        cesIssueDate,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateEvaluation = createAsyncThunk(
  'candidate/updateCandidateEvaluation',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      credentialEvaluatorId,
      processTypeId,
      startedDate,
      expirationDate,
      universityDocs,
      licenseDocsSubmitted,
      licenseDocsSubmissionMethodId,
      licenseDocsSubmissionDate,
      licenseDocsSubmissionTracking,
      licenseDocsSubmissionCarrier,
      translationSubmitted,
      translationSubmissionDate,
      universityDocsVerified,
      universityDocsVerificationDate,
      licenseVerified,
      licenseVerificationDate,
      cesIssueDate,
    } = payload;
    try {
      return await CandidateService.updateCandidateEvaluation(
        id,
        candidateId,
        credentialEvaluatorId,
        processTypeId,
        startedDate,
        expirationDate,
        universityDocs,
        licenseDocsSubmitted,
        licenseDocsSubmissionMethodId,
        licenseDocsSubmissionDate,
        licenseDocsSubmissionTracking,
        licenseDocsSubmissionCarrier,
        translationSubmitted,
        translationSubmissionDate,
        universityDocsVerified,
        universityDocsVerificationDate,
        licenseVerified,
        licenseVerificationDate,
        cesIssueDate,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateEvaluation = createAsyncThunk(
  'candidate/deleteCandidateEvaluation',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateEvaluation(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const assignCandidateEvaluationUniversityDoc = createAsyncThunk(
  'candidate/assignCandidateEvaluationUniversityDoc',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      typeId,
      documentId,
    } = payload;
    try {
      return await CandidateService.createCandidateEvaluationUniversityDocument(
        id,
        candidateId,
        typeId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const uploadCandidateEvaluationUniversityDoc = createAsyncThunk(
  'candidate/uploadCandidateEvaluationUniversityDoc',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      typeId,
      documentId,
      file,
    } = payload;

    try {
      return await CandidateService.uploadCandidateEvaluationUniversityDocument(
        id,
        candidateId,
        typeId,
        documentId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateEvaluationUniversityDoc = createAsyncThunk(
  'candidate/deleteCandidateEvaluationUniversityDoc',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      typeId,
      documentId,
    } = payload;

    try {
      return await CandidateService.deleteCandidateEvaluationUniversityDocument(
        id,
        candidateId,
        typeId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateMarketingContact = createAsyncThunk(
  'candidate/fetchCandidateMarketingContact',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await MarketingService.getContact(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateMarketingContact = createAsyncThunk(
  'candidate/updateCandidateMarketingContact',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      email,
      listIds,
    } = payload;
    try {
      return await MarketingService.updateContact(
        id,
        email,
        listIds,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const removeCandidateMarketingContact = createAsyncThunk(
  'candidate/removeCandidateMarketingContact',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      listIds,
    } = payload;
    try {
      return await MarketingService.removeContact(
        id,
        listIds,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateAgreements = createAsyncThunk(
  'candidate/fetchCandidateAgreements',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateAgreementsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateAgreement = createAsyncThunk(
  'candidate/createCandidateAgreement',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      parameters,
    } = payload;
    try {
      return await CandidateService.createCandidateAgreement(
        id,
        candidateId,
        parameters,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateAgreement = createAsyncThunk(
  'candidate/updateCandidateAgreement',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      signed,
      signedOn,
      assignedOn,
      expiresOn,
    } = payload;

    try {
      return await CandidateService.updateCandidateAgreement(
        id,
        candidateId,
        signed,
        signedOn,
        assignedOn,
        expiresOn,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const signCandidateAgreement = createAsyncThunk(
  'candidate/signCandidateAgreement',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;

    try {
      return await CandidateService.signCandidateAgreement(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateAgreementDocument = createAsyncThunk(
  'candidate/updateCandidateAgreementDocument',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      files,
    } = payload;

    try {
      return await CandidateService.updateCandidateAgreementDocument(
        id,
        candidateId,
        files,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateAgreement = createAsyncThunk(
  'candidate/deleteCandidateAgreement',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateAgreement(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateFingerprints = createAsyncThunk(
  'candidate/fetchCandidateFingerprints',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateFingerprintsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateFingerprint = createAsyncThunk(
  'candidate/createCandidateFingerprint',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      stateId,
      submissionDate,
      submissionCarrier,
      submissionTracking,
    } = payload;
    try {
      return await CandidateService.createCandidateFingerprint(
        candidateId,
        stateId,
        submissionDate,
        submissionCarrier,
        submissionTracking,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateFingerprint = createAsyncThunk(
  'candidate/updateCandidateFingerprint',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      stateId,
      submissionDate,
      submissionCarrier,
      submissionTracking,
    } = payload;

    try {
      return await CandidateService.updateCandidateFingerprint(
        id,
        candidateId,
        stateId,
        submissionDate,
        submissionCarrier,
        submissionTracking,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateFingerprint = createAsyncThunk(
  'candidate/deleteCandidateFingerprint',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateFingerprint(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateFingerprintDocs = createAsyncThunk(
  'candidate/createCandidateFingerprintDocs',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      templateId,
      parameters,
    } = payload;

    try {
      return await CandidateService.createCandidateFingerprintDocs(
        id,
        candidateId,
        templateId,
        parameters,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const uploadCandidateFingerprintDocs = createAsyncThunk(
  'candidate/uploadCandidateFingerprintDocs',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      documentId,
      file,
    } = payload;

    try {
      return await CandidateService.uploadCandidateFingerprintDocs(
        id,
        candidateId,
        documentId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateFingerprintDocs = createAsyncThunk(
  'candidate/deleteCandidateFingerprintDocs',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      documentId,
    } = payload;

    try {
      return await CandidateService.deleteCandidateFingerprintDocs(
        id,
        candidateId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateNursingBoards = createAsyncThunk(
  'candidate/fetchCandidateNursingBoards',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateNursingBoardsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateNursingBoard = createAsyncThunk(
  'candidate/createCandidateNursingBoard',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      stateId,
      applicationBy,
      date,
      expiration,
      applicantId,
      username,
      password,
    } = payload;
    try {
      return await CandidateService.createCandidateNursingBoard(
        candidateId,
        stateId,
        applicationBy,
        date,
        expiration,
        applicantId,
        username,
        password,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateNursingBoard = createAsyncThunk(
  'candidate/updateCandidateNursingBoard',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      stateId,
      applicationBy,
      date,
      expiration,
      applicantId,
      username,
      password,
    } = payload;

    try {
      return await CandidateService.updateCandidateNursingBoard(
        id,
        candidateId,
        applicationBy,
        stateId,
        date,
        expiration,
        applicantId,
        username,
        password,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateNursingBoard = createAsyncThunk(
  'candidate/deleteCandidateNursingBoard',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateNursingBoard(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const assignCandidateNursingBoardDoc = createAsyncThunk(
  'candidate/assignCandidateNursingBoardDoc',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      documentId,
    } = payload;
    try {
      return await CandidateService.createCandidateNursingBoardDocument(
        id,
        candidateId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const uploadCandidateNursingBoardDoc = createAsyncThunk(
  'candidate/uploadCandidateNursingBoardDoc',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      documentId,
      file,
    } = payload;

    try {
      return await CandidateService.uploadCandidateNursingBoardDocument(
        id,
        candidateId,
        documentId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateNursingBoardDoc = createAsyncThunk(
  'candidate/deleteCandidateNursingBoardDoc',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      documentId,
    } = payload;

    try {
      return await CandidateService.deleteCandidateNursingBoardDocument(
        id,
        candidateId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateInterviews = createAsyncThunk(
  'candidate/fetchCandidateInterviews',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateInterviewsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateInterview = createAsyncThunk(
  'candidate/createCandidateInterview',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      preparationEmailSentOn,
      preparationInterviewSchedule,
      interviewSchedule,
      offerStatus,
      positionListingId,
      offerReceived,
      offerReceivedDate,
    } = payload;
    try {
      return await CandidateService.createCandidateInterview(
        candidateId,
        preparationEmailSentOn,
        preparationInterviewSchedule,
        interviewSchedule,
        offerStatus,
        positionListingId,
        offerReceived,
        offerReceivedDate,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateInterview = createAsyncThunk(
  'candidate/updateCandidateInterview',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      preparationEmailSentOn,
      preparationInterviewSchedule,
      interviewSchedule,
      offerStatus,
      positionListingId,
      offerReceived,
      offerReceivedDate,
    } = payload;

    try {
      return await CandidateService.updateCandidateInterview(
        id,
        candidateId,
        preparationEmailSentOn,
        preparationInterviewSchedule,
        interviewSchedule,
        offerStatus,
        positionListingId,
        offerReceived,
        offerReceivedDate,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateInterview = createAsyncThunk(
  'candidate/deleteCandidateInterview',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateInterview(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateUserCandidate = createAsyncThunk(
  'candidate/updateUserCandidate',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      uuid,
    } = payload;

    try {
      return await CandidateService.updateUserCandidate(
        candidateId,
        uuid,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatePreferences = createAsyncThunk(
  'candidate/fetchCandidatePreferences',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidatePreferences(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidatePreferences = createAsyncThunk(
  'candidate/createCandidatePreferences',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      emailNotifications,
      languageId,
      timezoneId,
    } = payload;
    try {
      return await CandidateService.createCandidatePreferences(
        candidateId,
        emailNotifications,
        languageId,
        timezoneId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidatePreferences = createAsyncThunk(
  'candidate/updateCandidatePreferences',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      emailNotifications,
      languageId,
      timezoneId,
    } = payload;
    try {
      return await CandidateService.updateCandidatePreferences(
        id,
        candidateId,
        emailNotifications,
        languageId,
        timezoneId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateNclexs = createAsyncThunk(
  'candidate/fetchCandidateNclexs',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateNclexsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateNclex = createAsyncThunk(
  'candidate/createCandidateNclex',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      archerRegistrationDate,
      archerExpirationDate,
      archerUsername,
      archerPassword,
      saundersRegistrationDate,
      saundersExpirationDate,
      saundersUsername,
      saundersPassword,
      expectedDate,
    } = payload;
    try {
      return await CandidateService.createCandidateNclex(
        candidateId,
        archerRegistrationDate,
        archerExpirationDate,
        archerUsername,
        archerPassword,
        saundersRegistrationDate,
        saundersExpirationDate,
        saundersUsername,
        saundersPassword,
        expectedDate,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateNclex = createAsyncThunk(
  'candidate/updateCandidateNclex',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      archerRegistrationDate,
      archerExpirationDate,
      archerUsername,
      archerPassword,
      saundersRegistrationDate,
      saundersExpirationDate,
      saundersUsername,
      saundersPassword,
      expectedDate,
    } = payload;
    try {
      return await CandidateService.updateCandidateNclex(
        id,
        candidateId,
        archerRegistrationDate,
        archerExpirationDate,
        archerUsername,
        archerPassword,
        saundersRegistrationDate,
        saundersExpirationDate,
        saundersUsername,
        saundersPassword,
        expectedDate,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateNclex = createAsyncThunk(
  'candidate/deleteCandidateNclex',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateNclex(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateNclexTests = createAsyncThunk(
  'candidate/fetchCandidateNclexTests',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateNclexTestsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateNclexTest = createAsyncThunk(
  'candidate/createCandidateNclexTest',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      schedule,
      nclexResultId,
      countryId,
      attNumber,
      attReceived,
      attExpiration,
    } = payload;
    try {
      return await CandidateService.createCandidateNclexTest(
        candidateId,
        schedule,
        nclexResultId,
        countryId,
        attNumber,
        attReceived,
        attExpiration,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateNclexTest = createAsyncThunk(
  'candidate/updateCandidateNclexTest',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      schedule,
      nclexResultId,
      countryId,
      attNumber,
      attReceived,
      attExpiration,
    } = payload;
    try {
      return await CandidateService.updateCandidateNclexTest(
        id,
        candidateId,
        schedule,
        nclexResultId,
        countryId,
        attNumber,
        attReceived,
        attExpiration,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateNclexTest = createAsyncThunk(
  'candidate/deleteCandidateNclexTest',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateNclexTest(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const assignCandidateNclexTestDocument = createAsyncThunk(
  'candidate/assignCandidateNclexTestDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      testId,
      documentId,
    } = payload;
    try {
      return await CandidateService.createCandidateNclexTestDocument(
        candidateId,
        testId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const uploadCandidateNclexTestDocument = createAsyncThunk(
  'candidate/uploadCandidateNclexTestDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      testId,
      documentId,
      file,
    } = payload;

    try {
      return await CandidateService.updateCandidateNclexTestDocument(
        candidateId,
        testId,
        documentId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateNclexTestDocument = createAsyncThunk(
  'candidate/deleteCandidateNclexTestDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      testId,
      documentId,
    } = payload;

    try {
      return await CandidateService.deleteCandidateNclexTestDocument(
        candidateId,
        testId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateNclexAssessmentTests = createAsyncThunk(
  'candidate/fetchCandidateNclexAssessmentTests',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateNclexAssessmentTestsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateNclexAssessmentTest = createAsyncThunk(
  'candidate/createCandidateNclexAssessmentTest',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      date,
      score,
      result,
      questions,
      challengingTopicId,
      nclexReadinessId,
      typeTest,
    } = payload;
    try {
      return await CandidateService.createCandidateNclexAssessmentTest(
        candidateId,
        date,
        score,
        result,
        questions,
        challengingTopicId,
        nclexReadinessId,
        typeTest,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateNclexAssessmentTest = createAsyncThunk(
  'candidate/updateCandidateNclexAssessmentTest',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      date,
      score,
      result,
      questions,
      challengingTopicId,
      nclexReadinessId,
      typeTest,
    } = payload;
    try {
      return await CandidateService.updateCandidateNclexAssessmentTest(
        id,
        candidateId,
        date,
        score,
        result,
        questions,
        challengingTopicId,
        nclexReadinessId,
        typeTest,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateNclexAssessmentTest = createAsyncThunk(
  'candidate/deleteCandidateNclexAssessmentTest',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateNclexAssessmentTest(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatePearson = createAsyncThunk(
  'candidate/fetchCandidatePearson',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidatePearsonInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidatePearson = createAsyncThunk(
  'candidate/createCandidatePearson',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      user,
      password,
      registrationDate,
      ncsbnId,
      paymentDate,
    } = payload;
    try {
      return await CandidateService.createCandidatePearson(
        candidateId,
        user,
        password,
        registrationDate,
        ncsbnId,
        paymentDate,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidatePearson = createAsyncThunk(
  'candidate/updateCandidatePearson',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      user,
      password,
      registrationDate,
      ncsbnId,
      paymentDate,
    } = payload;
    try {
      return await CandidateService.updateCandidatePearson(
        id,
        candidateId,
        user,
        password,
        registrationDate,
        ncsbnId,
        paymentDate,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidatePearson = createAsyncThunk(
  'candidate/deleteCandidatePearson',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidatePearson(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateTemplateVariables = createAsyncThunk(
  'candidate/fetchCandidateTemplateVariables',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateTemplateVariables(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const assignAdvisors = createAsyncThunk(
  'candidate/assignAdvisors',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      advisors,
    } = payload;
    try {
      return await CandidateService.assignAdvisors(
        id,
        advisors,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateAdvisors = createAsyncThunk(
  'candidate/updateAdvisors',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      advisors,
    } = payload;
    try {
      return await CandidateService.updateAdvisors(
        id,
        advisors,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const removeAdvisors = createAsyncThunk(
  'candidate/removeAdvisors',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      advisors,
    } = payload;
    try {
      return await CandidateService.removeAdvisors(
        id,
        advisors,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateMeetings = createAsyncThunk(
  'candidate/fetchCandidateMeetings',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateMeetingsInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateMeetingsByType = createAsyncThunk(
  'candidate/fetchCandidateMeetingsByType',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      meetingTypeId,
    } = payload;
    try {
      return await CandidateService.getCandidateMeetingsByTypeInfo(uuid, meetingTypeId);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateMeeting = createAsyncThunk(
  'candidate/createCandidateMeeting',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      meetingTypeId,
      meetingOutcomeId,
      subject,
      starts,
      ends,
      notes,
      meetingAttendees,
      metadata,
    } = payload;
    try {
      return await CandidateService.createCandidateMeeting(
        candidateId,
        meetingTypeId,
        meetingOutcomeId,
        subject,
        starts,
        ends,
        notes,
        meetingAttendees,
        metadata,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateMeeting = createAsyncThunk(
  'candidate/updateCandidateMeeting',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      meetingTypeId,
      meetingOutcomeId,
      subject,
      starts,
      ends,
      notes,
      meetingAttendees,
      metadata,
    } = payload;

    try {
      return await CandidateService.updateCandidateMeeting(
        id,
        candidateId,
        meetingTypeId,
        meetingOutcomeId,
        subject,
        starts,
        ends,
        notes,
        meetingAttendees,
        metadata,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateMeeting = createAsyncThunk(
  'candidate/deleteCandidateMeeting',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateMeeting(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const preValidateCandidateInfo = createAsyncThunk(
  'candidate/preValidateCandidateInfo',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
    } = payload;
    try {
      return await CandidateService.preValidateCandidateInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const validateCandidateInfo = createAsyncThunk(
  'candidate/validateCandidateInfo',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
    } = payload;
    try {
      return await CandidateService.validateCandidateInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const importCandidates = createAsyncThunk(
  'candidate/importCandidates',
  async (payload, { rejectWithValue }) => {
    const {
      candidates,
      advisorId,
    } = payload;
    try {
      return await CandidateService.importCandidates(candidates, advisorId);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const importFinancialCandidate = createAsyncThunk(
  'candidate/importFinancialCandidate',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      importData,
    } = payload;
    try {
      return await CandidateService.importFinancialCandidate(candidateId, importData);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidatesLanguageProgress = createAsyncThunk(
  'candidate/fetchCandidatesLanguageProgress',
  async (payload, { rejectWithValue }) => {
    try {
      return await CandidateService.getCandidatesLanguageProgress();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidatesLanguageProgress = createAsyncThunk(
  'candidate/updateCandidatesLanguageProgress',
  async (payload, { rejectWithValue }) => {
    const {
      progress,
    } = payload;

    try {
      return await CandidateService.updateCandidatesLanguageProgress(progress);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const checkSimilarCandidates = createAsyncThunk(
  'candidate/checkSimilarCandidates',
  async (payload, { rejectWithValue }) => {
    const {
      firstName,
      lastName,
    } = payload;

    try {
      return await CandidateService.checkSimilarCandidates(firstName, lastName);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateActivity = createAsyncThunk(
  'candidate/fetchCandidateActivity',
  async (payload, { rejectWithValue }) => {
    const {
      uuid,
      page,
    } = payload;
    try {
      return await CandidateService.fetchCandidateActivity(uuid, page);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateImmigration = createAsyncThunk(
  'candidate/fetchCandidateImmigration',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateImmigration(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateImmigration = createAsyncThunk(
  'candidate/createCandidateImmigration',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      i140Starting,
      visaTypeId,
      visaStatusId,
      embassyAppointment,
      visaScreenAgencyId,
      visaScreenApplicationStart,
      visaScreenApplicationExpiration,
      visaScreenStart,
      visaScreenIssue,
      visaScreenExpiration,
      currentlyInUs,
      currentImmigrationStatus,
      visaCategory,
      asylumApproved,
      issuedEad,
      issuedSsn,
      priorityDate,
      immigrationIssues,
      immigrationIssuesDetail,
      legallyMarried,
      children,
    } = payload;
    try {
      return await CandidateService.createCandidateImmigration(
        candidateId,
        i140Starting,
        visaTypeId,
        visaStatusId,
        embassyAppointment,
        visaScreenAgencyId,
        visaScreenApplicationStart,
        visaScreenApplicationExpiration,
        visaScreenStart,
        visaScreenIssue,
        visaScreenExpiration,
        currentlyInUs,
        currentImmigrationStatus,
        visaCategory,
        asylumApproved,
        issuedEad,
        issuedSsn,
        priorityDate,
        immigrationIssues,
        immigrationIssuesDetail,
        legallyMarried,
        children,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateImmigration = createAsyncThunk(
  'candidate/updateCandidateImmigration',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      i140Starting,
      visaTypeId,
      visaStatusId,
      embassyAppointment,
      visaScreenAgencyId,
      visaScreenApplicationStart,
      visaScreenApplicationExpiration,
      visaScreenStart,
      visaScreenIssue,
      visaScreenExpiration,
      currentlyInUs,
      currentImmigrationStatus,
      visaCategory,
      asylumApproved,
      issuedEad,
      issuedSsn,
      priorityDate,
      immigrationIssues,
      immigrationIssuesDetail,
      legallyMarried,
      children,
    } = payload;
    try {
      return await CandidateService.updateCandidateImmigration(
        id,
        candidateId,
        i140Starting,
        visaTypeId,
        visaStatusId,
        embassyAppointment,
        visaScreenAgencyId,
        visaScreenApplicationStart,
        visaScreenApplicationExpiration,
        visaScreenStart,
        visaScreenIssue,
        visaScreenExpiration,
        currentlyInUs,
        currentImmigrationStatus,
        visaCategory,
        asylumApproved,
        issuedEad,
        issuedSsn,
        priorityDate,
        immigrationIssues,
        immigrationIssuesDetail,
        legallyMarried,
        children,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const assignCandidateImmigrationDocument = createAsyncThunk(
  'candidate/assignCandidateImmigrationDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      immigrationId,
      documentId,
    } = payload;
    try {
      return await CandidateService.createCandidateImmigrationDocument(
        candidateId,
        immigrationId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const uploadCandidateImmigrationDocument = createAsyncThunk(
  'candidate/uploadCandidateImmigrationDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      immigrationId,
      documentId,
      file,
    } = payload;

    try {
      return await CandidateService.updateCandidateImmigrationDocument(
        candidateId,
        immigrationId,
        documentId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateImmigrationDocument = createAsyncThunk(
  'candidate/deleteCandidateImmigrationDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      immigrationId,
      documentId,
    } = payload;

    try {
      return await CandidateService.deleteCandidateImmigrationDocument(
        candidateId,
        immigrationId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateRecruitment = createAsyncThunk(
  'candidate/fetchCandidateRecruitment',
  async (payload, { rejectWithValue }) => {
    const { uuid } = payload;
    try {
      return await CandidateService.getCandidateRecruitmentInfo(uuid);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createCandidateRecruitment = createAsyncThunk(
  'candidate/createCandidateRecruitment',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      positionListingId,
      recruitmentStageId,
      startDate,
      clientInfoSentOn,
      sentToClientOn,
      preparationEmail,
      preparationInterview,
      interviewDate,
      offerReceivedDate,
      offerStatusId,
      offerAcceptanceDate,
      offerRejectedBy,
      rejectedOn,
      rejectedInStage,
      expectedStartingDate,
      actualStartingDate,
      flightDate,
      attorneyContactId,
      rejectionNotes,
    } = payload;
    try {
      return await CandidateService.createCandidateRecruitment(
        candidateId,
        positionListingId,
        recruitmentStageId,
        startDate,
        clientInfoSentOn,
        sentToClientOn,
        preparationEmail,
        preparationInterview,
        interviewDate,
        offerReceivedDate,
        offerStatusId,
        offerAcceptanceDate,
        offerRejectedBy,
        rejectedOn,
        rejectedInStage,
        expectedStartingDate,
        actualStartingDate,
        flightDate,
        attorneyContactId,
        rejectionNotes,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateCandidateRecruitment = createAsyncThunk(
  'candidate/updateCandidateRecruitment',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
      positionListingId,
      recruitmentStageId,
      startDate,
      clientInfoSentOn,
      sentToClientOn,
      preparationEmail,
      preparationInterview,
      interviewDate,
      offerReceivedDate,
      offerStatusId,
      offerAcceptanceDate,
      offerRejectedBy,
      rejectedOn,
      rejectedInStage,
      expectedStartingDate,
      actualStartingDate,
      flightDate,
      attorneyContactId,
      rejectionNotes,
    } = payload;
    try {
      return await CandidateService.updateCandidateRecruitment(
        id,
        candidateId,
        positionListingId,
        recruitmentStageId,
        startDate,
        clientInfoSentOn,
        sentToClientOn,
        preparationEmail,
        preparationInterview,
        interviewDate,
        offerReceivedDate,
        offerStatusId,
        offerAcceptanceDate,
        offerRejectedBy,
        rejectedOn,
        rejectedInStage,
        expectedStartingDate,
        actualStartingDate,
        flightDate,
        attorneyContactId,
        rejectionNotes,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateRecruitment = createAsyncThunk(
  'candidate/deleteCandidateRecruitment',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      candidateId,
    } = payload;
    try {
      return await CandidateService.deleteCandidateRecruitment(
        id,
        candidateId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const assignCandidateRecruitmentDocument = createAsyncThunk(
  'candidate/assignCandidateRecruitmentDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      recruitmentId,
      documentId,
    } = payload;
    try {
      return await CandidateService.createCandidateRecruitmentDocument(
        candidateId,
        recruitmentId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const uploadCandidateRecruitmentDocument = createAsyncThunk(
  'candidate/uploadCandidateRecruitmentDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      recruitmentId,
      documentId,
      file,
    } = payload;

    try {
      return await CandidateService.updateCandidateRecruitmentDocument(
        candidateId,
        recruitmentId,
        documentId,
        file,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteCandidateRecruitmentDocument = createAsyncThunk(
  'candidate/deleteCandidateRecruitmentDocument',
  async (payload, { rejectWithValue }) => {
    const {
      candidateId,
      recruitmentId,
      documentId,
    } = payload;

    try {
      return await CandidateService.deleteCandidateRecruitmentDocument(
        candidateId,
        recruitmentId,
        documentId,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const generateCandidatesDropboxProps = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.uuid,
  label: `${item.first_name} ${item.last_name}`,
}));

/* eslint-disable no-param-reassign */
export const candidateSlice = createSlice({
  name: 'candidate',
  initialState: {
    candidates: [],
    candidatesOutlook: [],
    profile: {},
    statusHistory: [],
    personal: {},
    immigration: {},
    phones: [],
    emails: [],
    addresses: [],
    education: [],
    experience: [],
    experienceResume: undefined,
    experienceDocuments: [],
    languages: [],
    documents: [],
    credentials: [],
    credentialEvaluations: [],
    nclex: {},
    nclexTests: [],
    nclexAssessmentTests: [],
    notesTimeline: [],
    note: {},
    contact: {},
    reminder: {},
    expenses: [],
    agreements: [],
    fingerprints: [],
    nursingBoards: [],
    interviews: [],
    recruitments: [],
    expensesStats: {},
    upcoming: [],
    sendgridContact: {},
    preferences: {},
    importData: {
      data: [],
      dataPrepared: [],
      loaded: false,
      prepared: false,
      advisor_id: undefined,
    },
    templateVars: [],
    meetings: [],
    preValidate: {
      validated: false,
      isValid: false,
      messages: [],
    },
    languageProgress: [],
    similar: [],
    similarCandidates: [],
    similarEmails: [],
    similarCandidatesEmails: [],
    similarPhones: [],
    similarCandidatesPhones: [],
    activity: { data: [], meta: { total: 0, page: 0, pages: 0 } },
    currentView: '',
    goToView: undefined,
    currentSubView: '',
    goToSubView: undefined,
    editing: false,
    removeData: {
      emails: [],
      phones: [],
      addresses: [],
      degrees: [],
      experiences: [],
      languages: [],
      documents: [],
      credentials: [],
      credentialEvaluations: [],
      fingerprints: [],
      nursingBoards: [],
      interviews: [],
      nclexTests: [],
      nclexAssessmentTests: [],
      languageCourses: [],
      languageTests: [],
      meetings: [],
    },
    profileCreated: false,
    created: false,
    saved: false,
    failed: false,
    succeeded: false,
    reload: false,
    message: '',
  },
  reducers: {
    clearCandidate: (state) => {
      state.profile = {};
      state.statusHistory = [];
      state.personal = {};
      state.immigration = {};
      state.phones = [{
        phone: '',
        default: true,
        changed: true,
        valid: false,
      }];
      state.emails = [{
        email: '',
        default: true,
        changed: true,
        valid: false,
      }];
      state.addresses = [];
      state.education = [];
      state.experience = [];
      state.experienceResume = undefined;
      state.experienceDocuments = [];
      state.languages = [];
      state.documents = [];
      state.credentials = [];
      state.credentialEvaluations = [];
      state.nclex = {};
      state.nclexTests = [];
      state.nclexAssessmentTests = [];
      state.pearsonRegistrations = [];
      state.notesTimeline = [];
      state.note = {};
      state.contact = {};
      state.reminder = {};
      state.expenses = [];
      state.agreements = [];
      state.fingerprints = [];
      state.nursingBoards = [];
      state.interviews = [];
      state.recruitments = [];
      state.templateVars = [];
      state.meetings = [];
      state.similar = [];
      state.similarCandidates = [];
      state.similarEmails = [];
      state.similarCandidatesEmails = [];
      state.similarPhones = [];
      state.similarCandidatesPhones = [];
      state.activity = { data: [], meta: { total: 0, page: 0, pages: 0 } };
      state.preValidate = {
        validated: false,
        isValid: false,
        messages: [],
      };
      state.expensesStats = {};
      state.upcoming = [];
      state.sendgridContact = {};
      state.preferences = {};
      state.importData = {
        data: [],
        dataPrepared: [],
        loaded: false,
        prepared: false,
        advisor_id: undefined,
      };
      state.removeData = {
        emails: [],
        phones: [],
        addresses: [],
        degrees: [],
        experiences: [],
        languages: [],
        documents: [],
        credentials: [],
        credentialEvaluations: [],
        fingerprints: [],
        nursingBoards: [],
        interviews: [],
        nclexTests: [],
        nclexAssessmentTests: [],
        pearsonRegistrations: [],
        languageCourses: [],
        languageTests: [],
        meetings: [],
      };
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
    setProfileCreated: (state, action) => {
      state.profileCreated = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setPersonal: (state, action) => {
      state.personal = action.payload;
    },
    setImmigration: (state, action) => {
      state.immigration = action.payload;
    },
    setPhones: (state, action) => {
      state.phones = action.payload;
    },
    setEmails: (state, action) => {
      state.emails = action.payload;
    },
    setAddresses: (state, action) => {
      state.addresses = action.payload;
    },
    setEducation: (state, action) => {
      state.education = action.payload;
    },
    setExperience: (state, action) => {
      state.experience = action.payload;
    },
    setLanguages: (state, action) => {
      state.languages = action.payload;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setCredentials: (state, action) => {
      state.credentials = action.payload;
    },
    setCredentialEvaluations: (state, action) => {
      state.credentialEvaluations = action.payload;
    },
    setFingerprints: (state, action) => {
      state.fingerprints = action.payload;
    },
    setNursingBoards: (state, action) => {
      state.nursingBoards = action.payload;
    },
    setInterviews: (state, action) => {
      state.interviews = action.payload;
    },
    setRecruitments: (state, action) => {
      state.recruitments = action.payload;
    },
    setNclex: (state, action) => {
      state.nclex = action.payload;
    },
    setNclexTests: (state, action) => {
      state.nclexTests = action.payload;
    },
    setNclexAssessmentTests: (state, action) => {
      state.nclexAssessmentTests = action.payload;
    },
    setPearsonRegistrations: (state, action) => {
      state.pearsonRegistrations = action.payload;
    },
    setSignature: (state, action) => {
      state.signature = action.payload;
    },
    setExpenses: (state, action) => {
      state.expenses = action.payload;
    },
    setAgreements: (state, action) => {
      state.agreements = action.payload;
    },
    setExpensesStats: (state, action) => {
      state.expensesStats = action.payload;
    },
    setSendgridContact: (state, action) => {
      state.sendgridContact = action.payload;
    },
    setPreferences: (state, action) => {
      state.preferences = action.payload;
    },
    setImportData: (state, action) => {
      state.importData = action.payload;
    },
    setSimilarCandidates: (state, action) => {
      state.similarCandidates = action.payload;
      state.similar = state.similarCandidates
        .concat(state.similarCandidatesEmails)
        .concat(state.similarCandidatesPhones);
    },
    setSimilarCandidatesEmails: (state, action) => {
      state.similarCandidatesEmails = action.payload;
      state.similar = state.similarCandidates
        .concat(state.similarCandidatesEmails)
        .concat(state.similarCandidatesPhones);
    },
    setSimilarCandidatesPhones: (state, action) => {
      state.similarCandidatesPhones = action.payload;
      state.similar = state.similarCandidates
        .concat(state.similarCandidatesEmails)
        .concat(state.similarCandidatesPhones);
    },
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    setGoToView: (state, action) => {
      state.goToView = action.payload;
    },
    setMoveView: (state, action) => {
      state.currentView = action.payload;
      state.goToView = undefined;
    },
    setCurrentSubView: (state, action) => {
      state.currentSubView = action.payload;
    },
    setGoToSubView: (state, action) => {
      state.goToSubView = action.payload;
    },
    setMoveSubView: (state, action) => {
      state.currentSubView = action.payload;
      state.goToSubView = undefined;
    },
    setGoToViewSubView: (state, action) => {
      const {
        view,
        subview,
      } = action.payload;
      state.goToView = view;
      state.goToSubView = subview;
    },
    setRemoveData: (state, action) => {
      state.removeData = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setReload: (state, action) => {
      state.reload = action.payload;
    },
  },
  extraReducers: (builder) => builder.addMatcher(
    (action) => action.type.endsWith('/rejected'),
    rejectionReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/pending'),
    pendingReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/fulfilled'),
    (state, action) => {
      const performedAction = action.type.split('/');
      if (performedAction[0] === 'candidate') {
        switch (performedAction[1]) {
          case 'fetchCandidatesInfo':
            fulfilledReducer(state, action);
            state.candidates = generateCandidatesDropboxProps(action);
            break;
          case 'fetchCandidatesOutlook':
            fulfilledReducer(state, action);
            state.candidatesOutlook = generateCandidatesDropboxProps(action);
            break;
          case 'fetchCandidateProfile':
            fulfilledReducer(state, action);
            state.profile = action.payload;
            break;
          case 'createCandidateProfile':
            fulfilledReducer(state, action);
            state.profile = action.payload.data;
            state.profileCreated = true;
            break;
          case 'updateCandidateProfile':
            fulfilledSavedReducer(state, action);
            state.profile = action.payload.data;
            break;
          case 'deleteCandidateProfile':
            fulfilledReducer(state, action);
            break;
          case 'fetchCandidateStatusHistory':
            fulfilledReducer(state, action);
            state.statusHistory = action.payload;
            break;
          case 'fetchCandidatePhones':
            fulfilledReducer(state, action);
            state.succeeded = false;
            state.phones = action.payload;
            break;
          case 'createCandidatePhone':
          case 'updateCandidatePhone':
          case 'deleteCandidatePhone':
            fulfilledSavedReducer(state, action);
            state.phones = action.payload.data;
            break;
          case 'fetchCandidateEmails':
            fulfilledReducer(state, action);
            state.emails = action.payload;
            break;
          case 'createCandidateEmail':
          case 'updateCandidateEmail':
          case 'deleteCandidateEmail':
            fulfilledSavedReducer(state, action);
            state.emails = action.payload.data;
            break;
          case 'fetchCandidateAddresses':
            fulfilledReducer(state, action);
            state.addresses = action.payload;
            break;
          case 'createCandidateAddress':
          case 'updateCandidateAddress':
          case 'deleteCandidateAddress':
            fulfilledSavedReducer(state, action);
            state.addresses = action.payload.data;
            break;
          case 'fetchCandidateDegrees':
            fulfilledReducer(state, action);
            state.education = action.payload;
            break;
          case 'createCandidateDegree':
          case 'updateCandidateDegree':
          case 'deleteCandidateDegree':
            fulfilledSavedReducer(state, action);
            state.education = action.payload.data;
            break;
          case 'fetchCandidateExperiences':
            fulfilledReducer(state, action);
            state.experience = action.payload;
            break;
          case 'createCandidateExperience':
          case 'updateCandidateExperience':
          case 'deleteCandidateExperience':
            fulfilledSavedReducer(state, action);
            state.experience = action.payload.data;
            break;
          case 'fetchCandidateResume':
            state.experienceResume = action.payload;
            break;
          case 'fetchCandidateExperienceDocuments':
            fulfilledReducer(state, action);
            state.experienceDocuments = action.payload;
            break;
          case 'assignCandidateExperienceDocument':
          case 'deleteCandidateExperienceDocument':
          case 'uploadCandidateExperienceDocument':
            fulfilledSavedReducer(state, action);
            state.experienceDocuments = action.payload.data;
            break;
          case 'fetchCandidateExpenses':
            fulfilledReducer(state, action);
            state.expenses = action.payload;
            break;
          case 'fetchCandidateExpensesStats':
            fulfilledReducer(state, action);
            state.expensesStats = action.payload;
            break;
          case 'fetchCandidateNotes':
            fulfilledReducer(state, action);
            state.notes = action.payload;
            break;
          case 'fetchCandidateNote':
            fulfilledReducer(state, action);
            state.note = action.payload;
            break;
          case 'fetchCandidateReminders':
            fulfilledReducer(state, action);
            state.reminders = action.payload;
            break;
          case 'fetchCandidateReminder':
            fulfilledReducer(state, action);
            state.reminder = action.payload;
            break;
          case 'fetchCandidateContacts':
            fulfilledReducer(state, action);
            state.contacts = action.payload;
            break;
          case 'fetchCandidateContact':
            fulfilledReducer(state, action);
            state.contact = action.payload;
            break;
          case 'createCandidateContact':
          case 'updateCandidateContact':
          case 'deleteCandidateContact':
            fulfilledSavedReducer(state, action);
            state.contacts = action.payload.data;
            break;
          case 'fetchCandidateNotesTimeline':
            fulfilledReducer(state, action);
            state.notesTimeline = action.payload;
            break;
          case 'createCandidateNote':
          case 'createCandidateReminder':
          case 'updateCandidateNote':
          case 'updateCandidateReminder':
          case 'deleteCandidateNote':
          case 'deleteCandidateReminder':
            fulfilledSavedReducer(state, action);
            state.notesTimeline = action.payload.data;
            break;
          case 'fetchCandidateDocuments':
            fulfilledReducer(state, action);
            state.documents = action.payload.data;
            break;
          case 'createCandidateDocument':
          case 'updateCandidateDocument':
          case 'deleteCandidateDocument':
            fulfilledSavedReducer(state, action);
            state.documents = action.payload.data;
            break;
          case 'fetchCandidateLanguageProficiency':
            fulfilledReducer(state, action);
            state.languages = action.payload;
            break;
          case 'createCandidateLanguageProficiency':
          case 'updateCandidateLanguageProficiency':
          case 'deleteCandidateLanguageProficiency':
          case 'createCandidateLanguageCourseProgress':
          case 'updateCandidateLanguageCourseProgress':
          case 'deleteCandidateLanguageCourseProgress':
          case 'assignCandidateLanguageTestDocument':
          case 'deleteCandidateLanguageTestDocument':
          case 'uploadCandidateLanguageTestDocument':
            fulfilledSavedReducer(state, action);
            state.languages = action.payload.data;
            break;
          case 'fetchCandidatePersonal':
            fulfilledReducer(state, action);
            state.personal = action.payload;
            break;
          case 'createCandidatePersonal':
          case 'updateCandidatePersonal':
          case 'updateCandidatePersonalSignature':
          case 'updateCandidatePersonalPassport':
            fulfilledSavedReducer(state, action);
            state.personal = action.payload.data;
            break;
          case 'fetchCandidateUpcomingEvents':
            fulfilledReducer(state, action);
            state.upcoming = action.payload;
            break;
          case 'fetchCandidateLicenses':
            fulfilledReducer(state, action);
            state.credentials = action.payload;
            break;
          case 'createCandidateLicense':
          case 'updateCandidateLicense':
          case 'deleteCandidateLicense':
          case 'updateCandidateLicenseDocument':
            fulfilledSavedReducer(state, action);
            state.credentials = action.payload.data;
            break;
          case 'fetchCandidateEvaluations':
            fulfilledReducer(state, action);
            state.credentialEvaluations = action.payload;
            break;
          case 'createCandidateEvaluation':
          case 'updateCandidateEvaluation':
          case 'deleteCandidateEvaluation':
          case 'assignCandidateEvaluationUniversityDoc':
          case 'uploadCandidateEvaluationUniversityDoc':
          case 'deleteCandidateEvaluationUniversityDoc':
            fulfilledSavedReducer(state, action);
            state.credentialEvaluations = action.payload.data;
            break;
          case 'fetchCandidateMarketingContact':
            fulfilledReducer(state, action);
            state.sendgridContact = action.payload;
            break;
          case 'updateCandidateMarketingContact':
          case 'removeCandidateMarketingContact':
            fulfilledSavedReducer(state, action);
            break;
          case 'fetchCandidateAgreements':
            fulfilledReducer(state, action);
            state.agreements = action.payload.data;
            break;
          case 'signCandidateAgreement':
          case 'updateCandidateAgreementDocument':
          case 'createCandidateAgreement':
          case 'deleteCandidateAgreement':
            fulfilledCreatedReducer(state, action);
            state.agreements = action.payload.data;
            break;
          case 'updateCandidateAgreement':
            fulfilledSavedReducer(state, action);
            state.agreements = action.payload.data;
            break;
          case 'fetchCandidateFingerprints':
            fulfilledReducer(state, action);
            state.fingerprints = action.payload.data;
            break;
          case 'createCandidateFingerprint':
          case 'updateCandidateFingerprint':
          case 'deleteCandidateFingerprint':
            fulfilledSavedReducer(state, action);
            state.fingerprints = action.payload.data;
            break;
          case 'createCandidateFingerprintDocs':
          case 'uploadCandidateFingerprintDocs':
          case 'deleteCandidateFingerprintDocs':
            fulfilledCreatedReducer(state, action);
            state.fingerprints = action.payload.data;
            break;
          case 'fetchCandidateNursingBoards':
            fulfilledReducer(state, action);
            state.nursingBoards = action.payload.data;
            break;
          case 'createCandidateNursingBoard':
          case 'updateCandidateNursingBoard':
          case 'deleteCandidateNursingBoard':
          case 'assignCandidateNursingBoardDoc':
          case 'uploadCandidateNursingBoardDoc':
          case 'deleteCandidateNursingBoardDoc':
            fulfilledSavedReducer(state, action);
            state.nursingBoards = action.payload.data;
            break;
          case 'fetchCandidateInterviews':
            fulfilledReducer(state, action);
            state.interviews = action.payload.data;
            break;
          case 'createCandidateInterview':
          case 'updateCandidateInterview':
          case 'deleteCandidateInterview':
            fulfilledSavedReducer(state, action);
            state.interviews = action.payload.data;
            break;
          case 'fetchCandidatePreferences':
            fulfilledReducer(state, action);
            state.preferences = action.payload;
            break;
          case 'createCandidatePreferences':
          case 'updateCandidatePreferences':
            fulfilledSavedReducer(state, action);
            state.preferences = action.payload.data;
            break;
          case 'fetchCandidateNclexs':
            fulfilledReducer(state, action);
            state.nclex = action.payload;
            break;
          case 'createCandidateNclex':
          case 'updateCandidateNclex':
          case 'deleteCandidateNclex':
            fulfilledSavedReducer(state, action);
            state.nclex = action.payload.data;
            break;
          case 'fetchCandidateNclexTests':
            fulfilledReducer(state, action);
            state.nclexTests = action.payload;
            break;
          case 'createCandidateNclexTest':
          case 'updateCandidateNclexTest':
          case 'deleteCandidateNclexTest':
          case 'assignCandidateNclexTestDocument':
          case 'deleteCandidateNclexTestDocument':
          case 'uploadCandidateNclexTestDocument':
            fulfilledSavedReducer(state, action);
            state.nclexTests = action.payload.data;
            break;
          case 'fetchCandidateNclexAssessmentTests':
            fulfilledReducer(state, action);
            state.nclexAssessmentTests = action.payload;
            break;
          case 'createCandidateNclexAssessmentTest':
          case 'updateCandidateNclexAssessmentTest':
          case 'deleteCandidateNclexAssessmentTest':
            fulfilledSavedReducer(state, action);
            state.nclexAssessmentTests = action.payload.data;
            break;
          case 'fetchCandidatePearson':
            fulfilledReducer(state, action);
            state.pearsonRegistrations = action.payload;
            break;
          case 'createCandidatePearson':
          case 'updateCandidatePearson':
          case 'deleteCandidatePearson':
            fulfilledSavedReducer(state, action);
            state.pearsonRegistrations = action.payload.data;
            break;
          case 'fetchCandidateTemplateVariables':
            fulfilledReducer(state, action);
            state.templateVars = action.payload;
            break;
          case 'assignAdvisors':
          case 'updateAdvisors':
          case 'removeAdvisors':
            fulfilledSavedReducer(state, action);
            state.profile = action.payload.data;
            break;
          case 'fetchCandidateMeetings':
          case 'fetchCandidateMeetingsByType':
            fulfilledReducer(state, action);
            state.meetings = action.payload;
            break;
          case 'createCandidateMeeting':
          case 'updateCandidateMeeting':
          case 'deleteCandidateMeeting':
            fulfilledSavedReducer(state, action);
            state.meetings = action.payload.data;
            break;
          case 'preValidateCandidateInfo':
            fulfilledReducer(state, action);
            state.preValidate = action.payload;
            break;
          case 'validateCandidateInfo':
            fulfilledReducer(state, action);
            state.profile = action.payload;
            break;
          case 'importCandidates':
            fulfilledReducer(state, action);
            state.importData = action.payload;
            break;
          case 'importFinancialCandidate':
            fulfilledSavedReducer(state, action);
            break;
          case 'fetchCandidatesLanguageProgress':
          case 'updateCandidatesLanguageProgress':
            fulfilledReducer(state, action);
            state.languageProgress = action.payload;
            break;
          case 'checkSimilarCandidates':
            fulfilledReducer(state, action);
            state.similarCandidates = action.payload;
            state.similar = state.similarCandidates
              .concat(state.similarCandidatesEmails)
              .concat(state.similarCandidatesPhones);
            break;
          case 'checkSimilarCandidateEmail':
            fulfilledReducer(state, action);
            state.similarCandidatesEmails = action.payload.candidates;
            state.similarEmails = action.payload.emails;
            state.similar = state.similarCandidates
              .concat(state.similarCandidatesEmails)
              .concat(state.similarCandidatesPhones);
            break;
          case 'checkSimilarCandidatePhone':
            fulfilledReducer(state, action);
            state.similarCandidatesPhones = action.payload.candidates;
            state.similarPhones = action.payload.phones;
            state.similar = state.similarCandidates
              .concat(state.similarCandidatesEmails)
              .concat(state.similarCandidatesPhones);
            break;
          case 'fetchCandidateActivity':
            fulfilledReducer(state, action);
            state.activity = action.payload;
            break;
          case 'fetchCandidateImmigration':
            fulfilledReducer(state, action);
            state.immigration = action.payload;
            break;
          case 'createCandidateImmigration':
          case 'updateCandidateImmigration':
          case 'assignCandidateImmigrationDocument':
          case 'uploadCandidateImmigrationDocument':
          case 'deleteCandidateImmigrationDocument':
            fulfilledSavedReducer(state, action);
            state.immigration = action.payload.data;
            break;
          case 'fetchCandidateRecruitment':
            fulfilledReducer(state, action);
            state.recruitments = action.payload;
            break;
          case 'createCandidateRecruitment':
            fulfilledSavedReducer(state, action);
            state.recruitments = action.payload.data;
            break;
          case 'updateCandidateRecruitment':
          case 'deleteCandidateRecruitment':
          case 'assignCandidateRecruitmentDocument':
          case 'uploadCandidateRecruitmentDocument':
          case 'deleteCandidateRecruitmentDocument':
            fulfilledSavedReducer(state, action);
            state.recruitments = action.payload.data;
            break;
          default:
            fulfilledReducer(state, action);
            break;
        }
      }
    },
  ),
});
/* eslint-disable no-param-reassign */

export const {
  clearCandidate,
  setEditing,
  setProfileCreated,
  setCreated,
  setSaved,
  setFailed,
  setErrors,
  setProfile,
  setPersonal,
  setImmigration,
  setPhones,
  setEmails,
  setAddresses,
  setEducation,
  setExperience,
  setLanguages,
  setCredentials,
  setCredentialEvaluations,
  setFingerprints,
  setNursingBoards,
  setInterviews,
  setRecruitments,
  setNclex,
  setNclexTests,
  setNclexAssessmentTests,
  setPearsonRegistrations,
  setPreferences,
  setImportData,
  setSimilarCandidates,
  setSimilarCandidatesEmails,
  setSimilarCandidatesPhones,
  setCurrentView,
  setGoToView,
  setMoveView,
  setCurrentSubView,
  setGoToSubView,
  setMoveSubView,
  setGoToViewSubView,
  setRemoveData,
  setSucceeded,
} = candidateSlice.actions;

export default candidateSlice.reducer;
