import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Reducer functions
import { createLink, sendLink } from 'reducers/tokenLinksSlice';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';

// @material-ui core components
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// Components
import FormField from 'layouts/components/FormField';
import ReactLoading from 'react-loading';

const selector = (state) => ({
  tokenLinkInfo: state.link.tokenLinkInfo,
});

function ShareLink({ section, candidateId }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.token-links' });
  const {
    tokenLinkInfo,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(undefined);
  const [openCopyToolTip, setOpenCopyToolTip] = useState(false);
  const [openSendToolTip, setOpenSendToolTip] = useState(false);

  useEffect(() => {
    if (tokenLinkInfo !== undefined) {
      if (tokenLinkInfo.token !== undefined && tokenLinkInfo.token !== null) {
        setLink(`${process.env.REACT_APP_APP_URL}url/${tokenLinkInfo.token}`);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [tokenLinkInfo]);

  const handleCopyTooltipClose = () => {
    setOpenCopyToolTip(false);
  };

  const handleSendTooltipClose = () => {
    setOpenSendToolTip(false);
  };

  const handleGenerate = () => {
    setLoading(true);
    dispatch(createLink({ section, candidateId }));
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link)
      .then(setOpenCopyToolTip(true));
  };

  const handleSendLink = () => {
    setOpenSendToolTip(true);
    dispatch(sendLink({ token: tokenLinkInfo.token }));
  };

  return (
    <>
      {loading && (
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100px',
          }}
        >
          <Grid item>
            <ReactLoading
              type="spin"
              color="#03fc4e"
              height={100}
              width={100}
            />
          </Grid>
        </Grid>
      )}
      {(!loading && link === undefined) && (
        <MenuItem onClick={handleGenerate}>{t('share-link')}</MenuItem>
      )}
      {(!loading && link !== undefined) && (
        <MenuItem>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '250px',
            }}
          >
            <Grid item md={12}>
              <SuiTypography variant="caption" color="text">
                {t('link-description')}
              </SuiTypography>
            </Grid>
            <Grid item md={12}>
              <Grid
                container
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid item md={12}>
                  <FormField
                    size="small"
                    defaultValue={link}
                    disabled
                  />
                </Grid>
                <Grid item md={6}>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item>
                      <ClickAwayListener onClickAway={handleCopyTooltipClose}>
                        <Tooltip
                          title={t('link-copied')}
                          placement="top"
                          onClose={handleCopyTooltipClose}
                          open={openCopyToolTip}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          PopperProps={{
                            disablePortal: true,
                          }}
                        >
                          <SuiButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            sx={{ padding: '0.5rem 1rem' }}
                            onClick={handleCopyLink}
                          >
                            {t('copy')}
                          </SuiButton>
                        </Tooltip>
                      </ClickAwayListener>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item>
                      <ClickAwayListener onClickAway={handleSendTooltipClose}>
                        <Tooltip
                          title={t('link-send')}
                          placement="top"
                          onClose={handleSendTooltipClose}
                          open={openSendToolTip}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          PopperProps={{
                            disablePortal: true,
                          }}
                        >
                          <SuiButton
                            variant="outlined"
                            color="info"
                            size="small"
                            sx={{ padding: '0.5rem 1rem' }}
                            onClick={handleSendLink}
                          >
                            {t('send')}
                          </SuiButton>
                        </Tooltip>
                      </ClickAwayListener>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MenuItem>
      )}
    </>
  );
}

ShareLink.propTypes = {
  section: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
};

export default ShareLink;
