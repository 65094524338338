import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';

// @material-ui core components
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';

function Options({ children }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.options' });

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOptions = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <SuiButton
        variant="text"
        color="info"
        onClick={handleOptions}
      >
        {t('options')}
      </SuiButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {children}
      </Menu>
    </div>
  );
}

Options.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Options;
