import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// Reducer functions
import {
  fetchCandidateAgreements,
  fetchCandidatePersonal,
  fetchCandidateProfile,
  fetchCandidateTemplateVariables,
  setCurrentSubView,
} from 'reducers/candidatesSlice';
import { fetchAgreements } from 'reducers/agreementsSlice';
import { clearTokenLink } from 'reducers/tokenLinksSlice';

// Function
import { getCandidateUUID, setTitle } from 'Util';

// Components
import AgreementsList from './components/AgreementsList';

const selector = (state) => ({
  editing: state.candidate.editing,
});
function Agreements() {
  const { editing } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  setTitle('Candidate Agreements');

  useEffect(() => {
    dispatch(setCurrentSubView('agreements'));
    dispatch(fetchAgreements());
    dispatch(clearTokenLink());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
      dispatch(fetchCandidateAgreements({ uuid }));
      dispatch(fetchCandidateTemplateVariables({ uuid }));
      dispatch(fetchCandidatePersonal({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  return (
    <AgreementsList candidate={uuid} />
  );
}

export default Agreements;
